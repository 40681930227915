import React from 'react';
import './Contact.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

function Contact() {
  return (
    <Container fluid className="contact-us-container">
      <Row>
        <Col xs={12} md={6} className="p-0">
          <div className="outlet">
            <h2>KOCHI : MAKEUP STUDIO</h2>
            <p>
              <FaMapMarkerAlt />
              1/979-b , KB Jacob Rd, Opp Santa Cruz Basilica <br />
              Fort Kochi, Kochi, Kerala 682001
            </p>
            <p>
              <FaPhone />
              +91 8590248482 , +91 7511134484
            </p>
            <h3>SERVICES :</h3>
            <ul>
              <li>&#9670; bride & groom makeup</li>
              <li>&#9670; Microblading</li>
              <li>&#9670; Head Massage : Oil</li>
              <li>&#9670; Hair Spa</li>
            </ul>
          </div>
        </Col>
        <Col xs={12} md={6} className="p-0">
          <div className="outlet">
            <h2>KOLLAM: FAMILY SALON & MAKEUP STUDIO</h2>
            <p>
              <FaMapMarkerAlt />
              1st Floor, SK BUILDING<br />
              Opp. Lalas Convention Centre,<br />
              Near Travancore Medicity Hospital,<br />
              Bypass Road, Kollam-691020
            </p>
            <p>
              <FaPhone />
              +91 8590248482 , +91 7511134484<br />
              +91 9061575474
            </p>
            <h3>SERVICES :</h3>
            <ul>
              <li>&#9670; All hair and beauty services</li>
              <li>&#9670; Bride & Groom Makeup</li>
              <li>&#9670; Microblading</li>
              <li>&#9670; and more...</li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;