import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Contact from './Components/Contact/Contact';
import Home from './Components/Home/Home';


function App() {
  return (
    <Router>
      <div className="App">
        
        <Routes>
          <Route path = "/" element = {<Home />} />                                                  
          <Route path = "/Contact" element = {<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
