import React from 'react'
import './Home.css'
import { Link } from 'react-router-dom';
import f from './LaDivaBG.webp';
import l from './instaLogoGoldy.png'
import Button from 'react-bootstrap/Button';

function Home(){
    return(
        <header className="App-header">
      
        <img src={f} className="App-logo" alt="logo" />
        <a href='https://instagram.com/ladiva_hair_makeup?igshid=ZWIzMWE5ZmU3Zg=='> 
        <img src = {l} className='Insta-logo' />
        </a>
        <div className='centery'>
        <Link to="/Contact">
          <Button className='button'>
            Contact Us
          </Button>
        </Link>
        </div>
      </header>
      
    );
}

export default Home;